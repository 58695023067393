import React from "react";
import clsx from "clsx";
import { Carousel } from "@molecules";
import { Container, Image, Text, Button, ButtonAlt } from "@atoms";

const Service = ({
  bgColor,
  className: _className,
  descriptor,
  heading,
  image,
  url,
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col items-center space-y-4 px-4 text-center",
        _className
      )}
    >
      <div className="relative aspect-square w-1/2 sm:w-3/4">
        <Image image={image} fill className="rounded-full" />
      </div>
      <Text
        variant="h6"
        className={clsx("mt-4", {
          "text-mint": bgColor === "midnight",
        })}
      >
        {heading}
      </Text>
      <Text
        variant="sm"
        className={clsx("mt-2", {
          "text-white": bgColor === "midnight",
        })}
      >
        {descriptor}
      </Text>
      {url && <ButtonAlt to={url}>Learn More</ButtonAlt>}
    </div>
  );
};

const ServiceCarousel = ({
  copy,
  services,
  bgColor,
  className: _className,
}) => {
  return (
    <section
      className={clsx(
        "py-16 md:py-32",
        {
          "bg-midnight": bgColor === "midnight",
          "bg-white": bgColor === "white",
        },
        _className
      )}
    >
      <Container>
        <div className="mx-auto max-w-3xl">
          <Text
            richText
            className={clsx("text-center prose-p:text-lg lg:prose-p:text-xl", {
              "prose-dark": bgColor === "midnight",
            })}
          >
            {copy}
          </Text>
        </div>
        <div
          className={clsx("mt-16", {
            "px-4 md:px-8": services?.length > 1,
          })}
        >
          {services?.length === 1 && (
            <Service
              key={services[0].uid}
              bgColor={bgColor}
              className="mx-auto w-full sm:w-2/3 md:w-1/2 lg:w-1/3"
              {...services[0]}
            />
          )}
          {services?.length > 1 && (
            <Carousel minVisible={1} maxVisible={3} showInactive>
              {services.map(service => {
                return (
                  <Service key={service.uid} bgColor={bgColor} {...service} />
                );
              })}
            </Carousel>
          )}
          <div className="mt-16 flex items-center justify-center">
            <Button to="/services">View All</Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

ServiceCarousel.defaultProps = {};

export default ServiceCarousel;
